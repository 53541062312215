import React, { useEffect, useState, useRef } from "react";
import "./Modal.scss";
// import placeholderImage from "../../assets/image/Rectangle.png";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ePub from 'epubjs';
import Loader from "assets/image/loader.gif";
import micListeningGif from "assets/image/mic_listening.gif";
import { storage as LocalStorage } from "services/config/storage";

const Modal = ({ data, isOpen, onClose }) => {
  // console log all data on page load
  const [storyData, setStoryData] = useState({});
  const [epub_rendition, updateRendition] = useState({});
  const [showStartButton, setShowStartButton] = useState(false);
  const [showTryAgainButton, setShowTryAgainButton] = useState(false);
  const [recording, setRecording] = useState(false);
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [targetPhrase, setTargetPhrase] = useState("");
  const [session_id, setSessionId] = useState("");
  const [process_id, setProcessId] = useState("");
  const [stream, setStream] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showErrorDiv, setShowErrorDiv] = useState(true);
  const [showTextHighlight, setShowTextHighlight] = useState(true);
  const [showPrevButton, setShowPrevButton] = useState(true);
  const [showNextButton, setShowNextButton] = useState(true);
  const [apiResponse, setApiResponse] = useState("");
  const [stopRecButton, setStopRecButton] = useState(false);
  const [showRecording, setShowRecording] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [count, setcount] = useState(0);
  useEffect(() => {
    console.log("Data on page load:", data);
    setStoryData(data);
    loadAndDisplayEPUB();
  }, []);

  const recordAudio = () =>
    new Promise(async (resolve) => {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setStream(stream);
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks = [];

      mediaRecorder.addEventListener("dataavailable", (event) => {
        audioChunks.push(event.data);
      });

      const start = () => {
        mediaRecorder.start();
        setRecording(true);
      };

      const stop = () =>
        new Promise((resolve) => {
          console.log("called stop method");
          mediaRecorder.addEventListener("stop", () => {
            const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            const play = () => audio.play();
            setRecording(false);
            setAudioPlayer({ audioBlob, audioUrl, play });
            resolve({ audioBlob, audioUrl, play });
          });
          console.log("media recorder state", mediaRecorder.state);
          if (mediaRecorder.state !== "inactive") {
            mediaRecorder.stop();
          }
        });

      resolve({ start, stop });
    });

  const handleRecordClick = async () => {
    const audioInstance = await recordAudio();
    audioInstance.start();
    setTimeout(() => {
      audioInstance.stop();
    }, 5000); // Adjust the duration as needed
  };

  const logObjectKeysAndValues = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        console.log(`${key}: ${obj[key]}`);
      }
    }
  };

  const loadAndDisplayEPUB = () => {
    const book = ePub(data?.url);
    const rendition = book.renderTo("epub-file", { width: 600, height: 700 });
    updateRendition(rendition);

    // Set loading to true when starting to load EPUB
    setShowLoading(true);

    rendition.display().then(() => {
      console.log("EPUB rendering complete");
      setShowLoading(false); // Set loading to false when rendering is complete
    });

    const modalContent = document.querySelector(".modal-content");
    modalContent.classList.add("epub-content");
  };


  // write function to load epub data from storyData.url
  const prevPage = async () => {
    setcount(count - 1);
    console.log(count);
    if (count === 0) {
      setShowPrevButton(false);
      setShowNextButton(true);
      return;
    }

    if (epub_rendition.location.start.index == 4) {
      setShowStartButton(false);
      await epub_rendition.display(0);
    } else {
      await epub_rendition.prev();
    }

    // Check if the current page index is the first index
    if (epub_rendition.location.atStart) {
      setShowPrevButton(false);
      setShowNextButton(true);
    } else {
      setShowPrevButton(true);
      setShowNextButton(true);
    }

    let targetHtml = "";
    var highlightText = document.getElementById("textHighlight");
    var errorDiv = document.getElementById("error-message");
    var errorMessage = document.getElementById("error-message-full");
    var tryAgainButton = document.getElementById("try-again-button");
    var recordButton = document.getElementById("recordButton");

    // errorMessage.innerHTML = "?";
    // errorDiv.style.display = "none";
    highlightText.innerHTML = "?";
    highlightText.style.display = "none";
    // tryAgainButton.style.display = "none";
    // recordButton.disabled = false;
  };
  const getRandomID = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };
  const getShownText = () => {
    var range = epub_rendition.getRange(
      epub_rendition.currentLocation().start.cfi
    );
    var endRange = epub_rendition.getRange(
      epub_rendition.currentLocation().end.cfi
    );
    range.setEnd(endRange.startContainer, endRange.startOffset);
    let targetPhrase = range.toString();
    setTargetPhrase(targetPhrase);
  };

  const stopRecording = async () => {
    var mic_listening = document.getElementById("mic_listening");
    mic_listening.style.display = "none";
    setShowStartButton(true);
    setStopRecButton(false);
    setShowLoading(true);
    console.log("called stoped recrording");
    try {
      const audio = await recorder.stop();
      console.log("audio:", audio);

      var wavfromblob = new File([audio.audioBlob], "incomingaudioclip.wav");
      var audioFormdata = new FormData();
      audioFormdata.append("file", wavfromblob, "New Recording.wav");

      fetch("https://api.hello.getfreadom.com/api/misc/v1/media/", {
        method: "post",
        body: audioFormdata,
      })
        .then(response => response.json())
        .then(text => {
              var token = LocalStorage.fetch.authToken();
              fetch("https://api.hello.getfreadom.com/api/misc/v1/reading-fluency-iitb/", {
                method: "post",
                headers: { "Content-Type": "application/json"},
                body: JSON.stringify({
                  audio_path: text?.result?.file,
                  book_title: storyData.name,
                  process_id: process_id,
                  session_id: session_id,
                  grade: "Test POC",
                  page: epub_rendition.location.start.index-3,
                }),
              })
                .then(response => response.text())
                .then(text => {
                  console.log("text", text);
                  const json_res = JSON.parse(text);
                  console.log("json_res", json_res);
                  if (json_res?.result?.data?.status === "Error") {
                    const tryMore = window.confirm(`Error in Capturing/Processing Audio : \n
                      ${
                        json_res?.data?.ExceptionMessage?.errorMessage ||
                        "Internal Server Error. Contact Administrator."
                      }\r\n
                      Click on OK to Speak Again.
                    `);

                if (tryMore) {
                  // tryAgainButton.style.display = "none";
                  // Assuming startRecording is a function to start recording
                  startRecording();
                  return;
                }
              } else {
                // console.log("else");
                // let p = new DOMParser().parseFromString(json_res?.result?.marked_text, "text/xml");
                // console.log(p);
                // document.getElementById('')
                setApiResponse(json_res?.result?.marked_text);

                const allPTags = document.querySelectorAll("p");

                // Loop through each p tag
                allPTags.forEach((pTag) => {
                  // Check if the content matches the specified string
                  if (
                    pTag.textContent === "There are many fish in the ocean."
                  ) {
                    // Create a new element with the desired HTML structure

                    const newElement = document.createElement("p");
                    newElement.innerHTML = json_res?.result?.marked_text.slice(
                      3,
                      json_res?.result?.marked_text.length - 5
                    );

                    // Replace the old p tag with the new HTML structure
                    pTag.parentNode.replaceChild(newElement, pTag);
                  }
                });
                // var iframe = document.querySelector("iframe");
                // var targetHtml = "";
                // var ePubTextList = iframe.contentWindow.document.querySelectorAll("p");
                // ePubTextList.forEach(function (p) {
                //   targetHtml += p.outerHTML;
                //   p.remove();
                // });
                // var ifrDoc = iframe.contentDocument;

                // var elem = ifrDoc.createElement("div");
                // elem.innerHTML = json_res.data.marked_text;
                // ifrDoc.body.appendChild(elem);
              }

              // Assuming tryAgainButton, recordButton, next, prev, and stopButton are declared in your component's state or refs
              // tryAgainButton.disabled = false;
              // tryAgainButton.style.display = "inline-block";
              // recordButton.style.display = "inline-block";
              // Assuming keyListener is a function to handle key events
              // document.addEventListener("keyup", keyListener, false);
              // next.style.display = "block";
              // prev.style.display = "block";
              // stopButton.disabled = true;
            });
          // });
        });
    } catch (error) {
      console.error("Error in stopRecording:", error);
    }
  };
  const startRecording = async () => {

    setApiResponse(null);
    setShowStartButton(false);
    setShowTryAgainButton(false);
    var session_id = getRandomID();
    setSessionId(session_id);
    var process_id = getRandomID();
    setProcessId(process_id);
    var mic_listening = document.getElementById("mic_listening");

    setShowNextButton(false);
    setShowPrevButton(false);

    // tryAgainButton.style.display = "none";
    setShowTryAgainButton(false);
    // recordButton.style.display = "none";
    setShowStartButton(false);
    // errorMessage.innerHTML = "?";
    setShowErrorMessage(false);
    // errorDiv.style.display = "none";
    setShowErrorDiv(false);
    // highlightText.innerHTML = "?";
    // highlightText.style.display = "none";
    setShowTextHighlight(false);
    // stopButton.disabled = false;
    setStopRecButton(true);
    getShownText();
    mic_listening.style.display = "block";
    var recorder = await recordAudio();
    setRecorder(recorder);
    recorder.start();
    var options = {};

  };

  const nextPage = async () => {
    if (epub_rendition?.location?.atEnd) {
      console.log("Already at the last page");
      return;
    }
    console.log(count);
    setcount(count + 1);

    if (count >= 0) {
      setShowPrevButton(true);
    }

    // Check if the current location is already at the end


    if (epub_rendition.location.start.index < 4) {
      await epub_rendition.display(4);
    } else {
      await epub_rendition.next();
    }

    // Check if the current page index is the last index
    if (epub_rendition.location.atEnd) {
      setShowNextButton(false);
      setShowPrevButton(true);
    } else {
      setShowNextButton(true);
      setShowPrevButton(true);
    }
  };

  return (
    isOpen && (
      <div className="modal-overlay">
        <div className="modal-content">
          <div
            style={{
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              backgroundColor: "#F1EEF5",
            }}
          >
            <span
              className="close-icon"
              onClick={onClose}
              style={{ fontSize: "35px" }}
            >
              &times;
            </span>
          </div>

          <div className="navigation-icons">
            {console.log("state", showPrevButton)}
            {showPrevButton && (
              <div className="left-icon" id="prev" onClick={prevPage}>
                <FaChevronLeft className="nav-icon left-nav" />
              </div>
            )}
            {console.log("state", showPrevButton)}
            {showNextButton && (
              <div className="right-icon" id="next" onClick={nextPage}>
                <FaChevronRight className="nav-icon right-nav" />
              </div>
            )}
          </div>
          <div id="epub-file"></div>
          {showErrorDiv ? (
            <div className="errorDiv">
              <p id="error-message"></p>
              <p id="highlight-text"></p>
            </div>
          ) : null}
          <div>
            <div id="error-message" className="col-md-12">
              {showErrorMessage ? (
                <h2>
                  Error: <span id="error-message-full">?</span>
                </h2>
              ) : null}
            </div>
          </div>
          <div>
            <div id="api-response-text" dangerouslySetInnerHTML={{ __html: apiResponse }} className="col-md-12" >
              {/* {apiResponse} */}
            </div>
          </div>

          <div className="row text-center">
            <div
              style={{
                width: "100%",
                marginBottom: "0px",
                marginTop: "-10px",
                display: "none",
                verticalAlign: "baseline",
              }}
              id="mic_listening"
            >
              <img
                style={{ height: "5rem" }}
                className="img-recorder"
                src={micListeningGif}
                alt="Mic Listening"
              />
              <span style={{ fontSize: "1.4rem" }}>Recording now</span>
            </div>
          </div>
          <div>
            {showLoading ?
            <img style={{ height: '5rem' }} className="img-recorder" src={Loader} alt="loading" />
             : null}
          </div>
          <div className="modal-buttons">
            {showStartButton && ( <button className="record-button" onClick={startRecording} id="recordButton">Start</button>) }
            {showTryAgainButton && ( <button className="try-again-button" id="try-again-button">Try Again</button> ) }
            {stopRecButton && <button className="end-button" id="end-button" onClick={stopRecording}  >Stop Recording</button> }
          </div>
        </div>
        {showTextHighlight ? (
          <div
            id="textHighlight"
            // class="row text-center justify-content-center"
            className="row text-center justify-content-center"
            // style="margin-top: 20px; display: none"
            style={{ marginTop: "20px", display: "none" }}
          >
            ?
          </div>
        ) : null}
      </div>
    )
  );
};

export default Modal;
