import axios from "axios";
// import store from "store/index";
import { storage as LocalStorage } from "services/config/storage";
import { API_URL, F_LIVE_API_URL } from "./apiUrl";

const _authorizationHeaders = () => {
  // const token = store.getState().user.authToken || LocalStorage.fetch.authToken() || ""
  const token = LocalStorage.fetch.authToken() || "";
  return {
    Authorization: token ? `JWT ${token}` : "",
    "api-version": "1.1.0",
    "App-version": "2.0.1.0",
    "Device-type": "web",
    "Content-Type": "application/json",
  };
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error.response);
  }
);

const _authorizationHeadersFlive = () => {
  const token = LocalStorage.fetch.authToken(true) || "";
  return {
    Authorization: token ? `${token}` : "",
    "api-version": "1.1.0",
    "Content-Type": "application/json",
  };
};
// const _authorizationAdmin = () => {
//   const token =
//     "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im93bmVyIiwiZGV2aWNlX3Rva2VuIjoiMTI0IiwidXNlcl9pZCI6IjU4Y2ExMDE3LWY1NWItNDEzNS05YTA5LWFkZDk4NzQ2ODFmOCIsImV4cCI6MTY2MzkxNjIxOSwiY2hpbGRfZ3JhZGVfbGV2ZWwiOiIiLCJkZXZpY2VfdHlwZSI6ImFuZHJvaWQiLCJjaGlsZF9pZCI6IiIsImNoaWxkX2dyYWRlX2lkIjoiIiwiZW1haWwiOm51bGwsImRldmljZV9pZCI6IjEyMzQifQ.DHayA6RXZaBLIs3S-Qolhph_wSaB8iTfDC_Q5_iC0SE";
//   return {
//     Authorization: token ? `${token}` : "",
//     "api-version": "1.1.0",
//     "Content-Type": "application/json",
//   };
// };

const handleError = (err) => {
  if (err?.response?.status === 401) {
    LocalStorage.destroy.all();
  }
};

export const getRequest = async (
  url,
  headers = _authorizationHeaders(),
  is_flive = false
) => {
  try {
    let api_url = is_flive ? F_LIVE_API_URL : API_URL;
    if (url.includes("global-search"))
      api_url = "https://api.hello.getfreadom.com/api/";

    const res = await axios.get(api_url + url, {
      headers: Object.assign({}, headers),
    });

    if (res.result && url.includes("clevertap-utm-params")) return res;
    if (res.data && url.includes("news-fread/v1/?grade_id")) return res.data;

    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};
// export const getAdminRequest = async (
//   url,
//   headers = _authorizationAdmin(),
//   is_flive = false
// ) => {
//   try {
//     let api_url = is_flive ? F_LIVE_API_URL : API_URL;
//     if (url.includes("global-search"))
//       api_url = "https://api.hello.getfreadom.com/api/";
//     const res = await axios.get(api_url + url, {
//       headers: Object.assign({}, headers),
//     });
//     if (res.result && url.includes("clevertap-utm-params")) return res;
//     return res.data.result ? res.data.result : res.data;
//   } catch (err) {
//     handleError(err);
//   }
// };

export const getSearchRequest = async (
  url,
  headers = _authorizationHeaders(),
  is_flive = false
) => {
  try {
    const api_url = is_flive ? F_LIVE_API_URL : API_URL;
    // const api_url = "https://api.hello.getfreadom.com/api/";
    const res = await axios.get(api_url + url, {
      headers: Object.assign({}, headers),
    });
    return res.data;
  } catch (err) {
    console.log("error" + err.response);
    handleError(err);
  }
};

export const getGlobalSearchRequest = async (
  url,
  headers = _authorizationHeaders(),
  is_flive = false
) => {
  try {
    const api_url = is_flive ? F_LIVE_API_URL : API_URL;
    // const api_url = "https://api.hello.getfreadom.com/api/";
    const res = await axios.get(api_url + url, {
      headers: Object.assign({}, headers),
    });
    return res.data;
  } catch (err) {
    console.log("error" + err.response);
    handleError(err);
  }
};
export const getFliveRequest = async (
  url,
  is_flive = false,
  headers = _authorizationHeadersFlive()
) => {
  try {
    const api_url = is_flive ? F_LIVE_API_URL : API_URL;
    const res = await axios.get(api_url + url, {
      headers: Object.assign({}, headers),
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const getExternalRequest = async (url) => {
  try {
    const res = await axios.get(url);
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const getListRequest = async (
  url,
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios.get(API_URL + url, {
      headers: Object.assign({}, headers),
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const postRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "POST",
      headers: Object.assign({}, headers),
      data,
    });
    return res.data;

    // return res.data.result ? res.data.result : res.data;
  } catch (err) {
    // return await handleError(err);
    return err;
  }
};

export const postFliveRequest = async (
  url,
  data = {},
  is_flive = false,
  headers = _authorizationHeadersFlive()
) => {
  try {
    const api_url = is_flive ? F_LIVE_API_URL : API_URL;

    const res = await axios({
      url: api_url + url,
      method: "POST",
      headers: Object.assign({}, headers),
      data,
    });
    return res.data;

    // return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
    return err?.response || err;
  }
};
export const postLeadQuizRequest = async (
  url,
  data = {},
  is_flive = false,
  headers = {
    "Content-Type": "application/json",
  }
) => {
  try {
    const api_url = is_flive ? F_LIVE_API_URL : API_URL;
    const res = await axios({
      url: api_url + url,
      method: "POST",
      headers: Object.assign({}, headers),
      data,
    });
    return res.data;

    // return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
    return err?.response || err;
  }
};

export const putRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "PUT",
      headers: Object.assign({}, headers),
      data,
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
    return err?.response || err?.data || err;
  }
};

export const putFliveRequest = async (
  url,
  data = {},
  is_flive = false,
  headers = _authorizationHeadersFlive()
) => {
  try {
    const api_url = is_flive ? F_LIVE_API_URL : API_URL;
    const res = await axios({
      url: api_url + url,
      method: "PUT",
      headers: Object.assign({}, headers),
      data,
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const patchRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "PATCH",
      headers: Object.assign({}, headers),
      body: JSON.stringify(data),
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const deleteRequest = async (url, headers = _authorizationHeaders()) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "DELETE",
      headers: Object.assign({}, headers),
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const getRequestWithRawData = async (
  url,
  headers = _authorizationHeaders(),
  is_flive = false
) => {
  try {
    let api_url = is_flive ? F_LIVE_API_URL : API_URL;
    if (url.includes("global-search"))
      api_url = "https://api.hello.getfreadom.com/api/";

    const res = await axios.get(api_url + url, {
      headers: Object.assign({}, headers),
    });

    if (res.result && url.includes("clevertap-utm-params")) return res;

    return res.data;
  } catch (err) {
    handleError(err);
  }
};

export const postTockenRequest = async (
  url,
  data = {},
) => {
  const token = data;
  console.log("token", token);
  const header = {
    Authorization: `JWT ${token}`
  };
  try {
    let api_url = API_URL;
    const res = await axios.post(api_url + url,data,  {
      headers: Object.assign({}, header) } );
    return res.data;
  } catch (err) {
    handleError(err);
  }  
}

export const Api = {
  deleteRequest,
  getListRequest,
  getRequest,
  getFliveRequest,
  putFliveRequest,
  getExternalRequest,
  postRequest,
  putRequest,
  patchRequest,
  postFliveRequest,
  getSearchRequest,
  getRequestWithRawData,
  //getAdminRequest,
  postTockenRequest,
  getGlobalSearchRequest
};
